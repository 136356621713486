import { Grid } from "@chakra-ui/react";
import BigNumber from "bignumber.js";

import { route } from "nextjs-routes";

import config from "configs/app";
import useApiQuery from "lib/api/useApiQuery";
import { WEI } from "lib/consts";
import { HOMEPAGE_STATS, STATS_COUNTERS } from "stubs/stats";
import GasInfoTooltipContent from "ui/shared/GasInfoTooltipContent/GasInfoTooltipContent";

import { formatLargeNumber } from "lib/utils/formatLargeNumber";
import StatsItem from "./StatsItem";

const hasGasTracker = config.UI.homepage.showGasTracker;
const hasAvgBlockTime = config.UI.homepage.showAvgBlockTime;

const Stats = () => {
  const {
    data: counters,
    isError: isErrorCounters,
    isPlaceholderData: isPlaceholderDataCounters,
  } = useApiQuery("gateway_stats_counters", {
    queryOptions: {
      placeholderData: STATS_COUNTERS,
    },
  });

  const {
    data: stats,
    isPlaceholderData: isPlaceholderDataStats,
    isError: isErrorStats,
  } = useApiQuery("homepage_stats", {
    queryOptions: {
      placeholderData: HOMEPAGE_STATS,
    },
  });

  const isError = isErrorCounters || isErrorStats;
  const isLoading = isPlaceholderDataCounters || isPlaceholderDataStats;

  if (isError || !counters || !stats) {
    return null;
  }

  let itemsCount = 4;
  !hasGasTracker && itemsCount--;
  !hasAvgBlockTime && itemsCount--;

  !stats.gas_prices && itemsCount--;
  stats.rootstock_locked_btc && itemsCount++;

  const gasLabel =
    hasGasTracker && stats.gas_prices ? (
      <GasInfoTooltipContent gasPrices={stats.gas_prices} />
    ) : null;

  return (
    <Grid
      flex={1}
      height={{ base: "unset", md: "10.75rem" }}
      gridTemplateColumns={{
        md: `repeat(${itemsCount}, 1fr)`,
        base: "1fr 1fr",
      }}
      gridTemplateRows={{ base: "50% 50%", lg: "100%" }}
      backgroundColor="white"
      border="1px solid"
      borderColor="neutral.light.3"
      rounded="0.5rem"
      paddingY={{ base: 0, md: 5 }}
    >
      <>
        {hasAvgBlockTime && (
          <StatsItem
            icon="average-time"
            title="Average block time"
            value={`${BigNumber(
              counters.find((x) => x.id === "averageBlockTime")?.value as never,
            ).toFixed(1)}s`}
            isLoading={isLoading}
          />
        )}
        <StatsItem
          icon="total-transaction"
          title="Total transactions"
          value={formatLargeNumber(
            counters.find((x) => x.id === "totalTxns")?.value,
            {
              accuracy: {
                base: 3,
                pivot: 1e7,
                upper: 0,
              },
              sticky: true,
            },
          )}
          url={route({ pathname: "/txs" })}
          isLoading={isLoading}
        />
        <StatsItem
          icon="wallet-green"
          title="Wallet addresses"
          value={formatLargeNumber(stats.total_addresses, {
            accuracy: {
              base: 3,
              pivot: 1e7,
              upper: 0,
            },
            sticky: true,
          })}
          isLoading={isLoading}
        />
        {hasGasTracker && stats.gas_prices && (
          <StatsItem
            icon="gas-tracker"
            title="Gas tracker"
            value={
              stats.gas_prices.average !== null
                ? `${BigNumber(stats.gas_prices.average).toFormat()}`
                : "N/A"
            }
            tooltipLabel={gasLabel}
            isLoading={isLoading}
          />
        )}
        {stats.rootstock_locked_btc && (
          <StatsItem
            icon="coins/bitcoin"
            title="BTC Locked in 2WP"
            value={`${BigNumber(stats.rootstock_locked_btc)
              .div(WEI)
              .dp(0)
              .toFormat()} RBTC`}
            isLoading={isLoading}
          />
        )}
      </>
    </Grid>
  );
};

export default Stats;
