import type { Route } from "nextjs-routes";

const TEMPLATE_MAP: Record<Route["pathname"], string> = {
  "/": "The comprehensive explorer for the Sei Network",
  "/txs": "Sei Network Transactions Information",
  "/tx/[hash]": "Transaction %hash%",
  "/blocks": "Sei Network Blocks",
  "/block/[height_or_hash]":
    "View the details, transactions for block %height_or_hash%",
  "/accounts": "Top Accounts by SEI balance",
  "/address/[hash]": "Address details for %hash%",
  "/verified-contracts": "SEI Network Verified Contracts",
  "/contract-verification": "Verify contract",
  "/address/[hash]/contract-verification": "Contract verification for %hash%",
  "/tokens": "Token tracker",
  "/nfts": "NFT collections tracker",
  "/token/[...slug]":
    "View the inventory, token transfer, holders and contract for %hash% on %network_title%",
  // "/token/[hash]/instance/[id]":
  //   "View the details, token transfer, properties for %id% %hash% on %network_title%",
  "/apps": "apps marketplace",
  "/apps/[id]": "- %app_name%",
  "/stats": "Statistics",
  "/api-docs": "REST API",
  "/graphiql": "GraphQL",
  "/search-results": "search result for %q%",
  "/auth/profile": "My profile",
  "/auth/profile/account": "My profile",
  "/auth/profile/watchlist": "Watch list",
  "/auth/profile/private-tags": "Private tags",
  "/auth/profile/api-keys": "API keys",
  "/account/custom-abi": "- custom ABI",
  "/account/public-tags-request": "- public tag requests",
  "/account/verified-addresses": "- my verified addresses",
  "/withdrawals": "withdrawals",
  "/visualize/sol2uml": "Solidity UML diagram",
  "/csv-export": "Export data to CSV",
  "/l2-deposits": "deposits (L1 > L2)",
  "/l2-output-roots": "output roots",
  "/l2-withdrawals": "withdrawals (L2 > L1)",
  "/404": "error - page not found",

  // service routes, added only to make typescript happy
  "/api/media-type": "node API media type",
  "/api/proxy": "node API proxy",
  "/api/csrf": "node API CSRF token",
  "/api/healthz": "node API health check",
  // @ts-ignore
  "/auth/auth0": "authentication",
  "/auth/unverified-email": "unverified email",
  "/validators": "SEI Network Validators",
  "/validator/[hash]": "Validator %hash% profile",
  "/proposals": "SEI Network Governance Proposals",
  "/proposal/[id]": "Proposal %id%",

  // auth routes
  "/auth/auth0_v2/callback": "Authentication",

  "/terms": "Terms & Conditions",
  "/privacy": "Privacy Notice",
  "/utilities": "Utilities",

  // ibc
  "/ibc-relayers": "IBC Relayers",
  "/ibc-relayer/[channel_id]/counterparty/[counterparty_channel_id]":
    "IBC Relayer %channel_id%",
  "/token/ibc/[hash]":
    "View the IBC token transfer and holders for %hash% on %network_title%",

  // pamaters
  "/parameters": "Parameters",

  // subscription
  "/insights": "Insights",
  "/insights/api": "Insights API",
  "/subscriptions/[id]": "API subscription",
};

export function make(pathname: Route["pathname"]) {
  const template = TEMPLATE_MAP[pathname] ?? TEMPLATE_MAP["/"];
  return `${template}`;
}
