import { HStack, StackProps, Text } from "@chakra-ui/react";
import { getEnvValueV2 } from "configs/app/utils";
import { memo } from "react";
import IconSvg from "./IconSvg";
import LinkExternal from "./LinkExternal";

type Props = {
  //
} & StackProps;

const PIT_URL = getEnvValueV2(`common.pit.URL`)!;

const PitPromotion = ({ ...props }: Props) => {
  return (
    <HStack
      spacing={0}
      {...props}
      alignItems="center"
      userSelect="none"
      flexWrap="wrap" /* Để tự động xuống dòng */
    >
      <Text
        as="span"
        textStyle="87500"
        whiteSpace="nowrap"
        color="neutral.light.6"
        flexShrink={0} /* Giữ cố định không bị thu nhỏ */
        mr={2}
      >
        Sponsored:
      </Text>

      <IconSvg flexShrink={0} boxSize={5} src="/images/logo/pit.svg" mr={2} />

      <Text
        as="span"
        whiteSpace="nowrap"
        textStyle="875700"
        flexShrink={0}
        mr={1}
      >
        Pit.Finance
      </Text>

      <Text
        as="span"
        textStyle="875"
        flexShrink={0}
        color="neutral.light.6"
        mr={1}
      >
        - Maximizing
      </Text>
      <Text
        as="span"
        textStyle="875"
        flexShrink={0}
        color="neutral.light.6"
        mr={1}
      >
        SEI,
      </Text>
      <Text
        as="span"
        textStyle="875"
        flexShrink={0}
        color="neutral.light.6"
        mr={1}
      >
        iSEI,
      </Text>
      <Text
        as="span"
        textStyle="875"
        flexShrink={0}
        color="neutral.light.6"
        mr={1}
      >
        USDC,
      </Text>
      <Text
        as="span"
        textStyle="875"
        flexShrink={0}
        color="neutral.light.6"
        mr={1}
      >
        USDT
      </Text>
      <Text
        as="span"
        textStyle="875"
        flexShrink={0}
        color="neutral.light.6"
        mr={1}
      >
        yields
      </Text>
      <Text
        as="span"
        textStyle="875"
        flexShrink={0}
        color="neutral.light.6"
        mr={1}
      >
        on
      </Text>
      <Text
        as="span"
        textStyle="875"
        flexShrink={0}
        color="neutral.light.6"
        mr={1}
      >
        Sei Network
      </Text>
      <Text
        as="span"
        textStyle="875"
        flexShrink={0}
        color="neutral.light.6"
        mr={1}
      >
        with
      </Text>
      <Text
        as="span"
        textStyle="875"
        flexShrink={0}
        color="neutral.light.6"
        mr={1}
      >
        advanced
      </Text>
      <Text
        as="span"
        textStyle="875"
        flexShrink={0}
        color="neutral.light.6"
        mr={1}
      >
        strategies.
      </Text>

      <LinkExternal href={PIT_URL} textStyle="87500" noIcon>
        Deposit now!
      </LinkExternal>
    </HStack>
  );
};

export default memo(PitPromotion);
